import { OpenSourceSectionDTO } from '~/pages/resources/types';

import githubIcon from './assets/github.svg';
import certIcon from './assets/cert-alerts.svg';
import nestIcon from './assets/nest.svg';

const openSourceSection: OpenSourceSectionDTO = {
  title: 'Open Source',
  description:
    'Use our solutions published on <a href="https://github.com/SwingDev" target="_blank" rel="noopener noreferrer">GitHub</a> for your own projects – free of charge. We love open source!',
  items: [
    {
      title: 'Express Graceful Shutdown',
      description:
        'Sleep well at night knowing that your node app will handle exceptions in a predictable way.',
      icon: {
        src: githubIcon,
        alt: 'Code icon',
      },
      url: 'https://github.com/SwingDev/express-graceful-shutdown-handler',
      urlLabel: 'View on GitHub',
    },
    {
      title: 'S3 Browser Direct Upload',
      description:
        'Upload files to Amazon S3 easily straight from browser/mobile (using Node.js).',
      icon: {
        src: githubIcon,
        alt: 'Code icon',
      },
      url: 'https://github.com/SwingDev/s3-browser-direct-upload',
      urlLabel: 'View on GitHub',
    },
    {
      title: 'Cert Alerts',
      description:
        'SSL Certificates have a tendency to expire in the worst possible moment. Never miss the date with certalerts.io.',
      icon: {
        src: certIcon,
        alt: 'Code icon',
      },
      url: 'https://certalerts.io',
      urlLabel: 'Check out Cert Alerts',
    },

    {
      title: 'NestJS',
      description:
        'Node.js: Were sponsors of a progressive framework for building efficient and scalable server-side apps',
      icon: {
        src: nestIcon,
        alt: 'Code icon',
      },
      url: 'https://nestjs.com/',
      urlLabel: 'Check out Nest',
    },
  ],
};

export default openSourceSection;
