import React from 'react';

import styles from './Section.module.scss';
import classNames from 'classnames';

type Props = {
  title?: string;
  description?: string;
  lastSection?: boolean;
  innerRef?: React.RefObject<HTMLDivElement>;
};

const Section: React.FC<Props> = ({
  title,
  description,
  children,
  lastSection,
  innerRef,
}) => {
  const classes = classNames(styles.root, {
    [styles.lastSection]: lastSection,
  });

  return (
    <section className={classes} {...(innerRef && { ref: innerRef })}>
      {title && <h2 className={styles.headline}>{title}</h2>}
      {description && (
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {children}
    </section>
  );
};

export default Section;
