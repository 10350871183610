import { DesignSectionDTO } from '~/pages/resources/types';
import greenLockImage from './assets/dribbble-green-lock.jpg';
import sofaImage from './assets/dribbble-sofa.jpg';
import witcherImage from './assets/dribbble-witcher.jpg';

const designSection: DesignSectionDTO = {
  title: 'Design Inspirations',
  description:
    'See what’s cooking at our Design Team. Visit our <a href="http://dribbble.com/swingdev" target="_blank" rel="noopener noreferrer">Dribbble profile</a> to browse through more of our projects and get inspired!',
  items: [
    {
      title: 'Green Lock – SSL Certificates Watcher Dashboard',
      image: {
        src: greenLockImage,
        alt: 'Green Lock – SSL Certificates Watcher Dashboard',
      },
      url:
        'https://dribbble.com/shots/9390976-Green-Lock-SSL-Certificates-Watcher-Dashboard',
    },
    {
      title: 'The Witcher – Web Concept',
      image: {
        src: witcherImage,
        alt: 'The Witcher – Web Concept',
      },
      url: 'https://dribbble.com/shots/9113159-The-Witcher-Web-Concept',
    },
    {
      title: 'Sofa Company – Redesign Concept',
      image: {
        src: sofaImage,
        alt: 'Sofa Company – Redesign Concept',
      },
      url: 'https://dribbble.com/shots/8553504-Sofa-Company-Redesign-Concept',
    },
  ],
};

export default designSection;
