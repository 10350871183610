export const formatDate = (date: string): string => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const d = new Date(date);

  return `${d.getDate()} ${monthNames[d.getMonth()]}`;
};
