import React from 'react';
import styles from './Slide.module.scss';

type Props = {
  title: string;
  description: string;
  link: string;
  iconUrl: string;
  linkLabel: string;
};

const Slide: React.FC<Props> = ({
  title,
  description,
  link,
  iconUrl,
  linkLabel,
}) => {
  return (
    <article className={styles.root}>
      <div className={styles.slide}>
        <a
          href={link}
          target='_blank'
          rel='noopener noreferrer'
          className={styles.link}
        >
          <img
            className={styles.figure}
            src={iconUrl}
            alt={title}
            role='presentation'
          />
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.description}>{description}</p>
          <div className={styles.viewMore}>{linkLabel}</div>
        </a>
      </div>
    </article>
  );
};

export default Slide;
