import React from 'react';
import Layout from '~/components/Layout';
import resources from '~/data/resources';
import HeroImage from '~/components/HeroImage';
import Articles from './components/Articles';
import OpenSource from './components/OpenSource';
import Videos from './components/Videos';
import Design from './components/Design';

const Resources = () => {
  const videoRef: React.RefObject<HTMLDivElement> = React.createRef();

  const {
    metadata,
    entrySection,
    openSourceSection,
    articlesSection,
    videosSection,
    designSection,
  } = resources;

  return (
    <Layout
      pageTitle={metadata.pageTitle}
      metaDescription={metadata.metaDescription}
    >
      <HeroImage scrollToRef={videoRef} data={entrySection} />
      <Videos innerRef={videoRef} data={videosSection} />
      <Articles data={articlesSection} />
      <OpenSource data={openSourceSection} />
      <Design data={designSection} />
    </Layout>
  );
};

export default Resources;
