import React, { useCallback } from 'react';

import { AnalyticsService } from '~/services/Analytics';
import Button from '~/components/Button';

import styles from './Article.module.scss';

type Props = {
  title: string;
  publishedAt: string;
  text: string;
  link: string;
  buttonLabel: string;
  photo: string;
};

const Article: React.FC<Props> = ({
  link,
  photo,
  text,
  title,
  publishedAt,
  buttonLabel,
}) => {
  const handleArticleClick = useCallback(() => {
    AnalyticsService.track({
      category: 'Resources',
      action: 'Article Click',
      label: title,
    });
  }, [title]);

  return (
    <article className={styles.article}>
      <Button
        as={{
          tag: 'a',
          href: link,
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
        onClick={handleArticleClick}
        className={styles.url}
        isText
      >
        <figure className={styles.figure}>
          <img className={styles.photo} src={photo} alt='' />
        </figure>
        <div className={styles.subtitle}>{publishedAt}</div>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.text}>{text}</p>
        <div className={styles.button}>{buttonLabel}</div>
      </Button>
    </article>
  );
};

export default Article;
