import { EntrySectionDTO } from '~/pages/resources/types';

import backgroundPhoto from './assets/hero-resources.jpg';

const entrySection: EntrySectionDTO = {
  title: 'Sharing Is Caring',
  subtitle:
    'We create content that helps development & business communities grow. Explore our latest videos, podcasts, articles, and open source projects. <strong>Get inspired!</strong>',
  image: {
    src: backgroundPhoto,
    alt: 'Sharing Is Caring',
  },
  buttonLabel: 'CHECK OUT OUR RESOURCES',
};

export default entrySection;
