import { ArticlesSectionDTO } from '~/pages/resources/types';
import { parseMediumData } from './utils/parseMediumData';
import mediumData from './assets/fallbackMediumData.json';

const articlesSection: ArticlesSectionDTO = {
  title: 'Latest from our Blog',
  description:
    'Learn about business, programming, design, and managing IT projects. Check out <a href="https://medium.com/swinginc" target="_blank" rel="noopener noreferrer">SwingDev Insights</a> to get more content.',
  items: parseMediumData(mediumData),
  firstItemSubtitle: 'Last article',
  itemButtonLabel: 'READ',
};

export default articlesSection;
