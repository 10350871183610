import { ArticleDTO } from '~/pages/resources/types';
import { MediumData } from './types';

const MEDIUM_CDN_URL = 'https://cdn-images-1.medium.com';

export const parseMediumData = (data: MediumData): ArticleDTO[] => {
  const rawPosts = Object.values(data.payload.references.Post).slice(0, 3);

  const posts = rawPosts.map((post) => {
    const {
      originalWidth,
      originalHeight,
      imageId,
    } = post.virtuals.previewImage;

    return {
      id: post.id,
      title: post.title,
      excerpt: post.virtuals.subtitle,
      slug: post.uniqueSlug,
      imageUrl: `${MEDIUM_CDN_URL}/fit/t/${originalWidth}/${originalHeight}/${imageId}`,
      publishedAt: new Date(post.firstPublishedAt).toDateString(),
    };
  });

  return posts;
};
