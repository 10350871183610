import React from 'react';

import { MEDIUM_URL } from '~/constants';
import Section from '../Section';
import { formatDate } from '~/pages/resources/utils';

import { ArticlesSectionDTO } from '../../types';

import Article from './Article';
import styles from './Articles.module.scss';

type Props = {
  data: ArticlesSectionDTO;
};

const Articles: React.FC<Props> = ({
  data: { title, items, itemButtonLabel, firstItemSubtitle, description },
}) => {
  const itemsWithDate = items.map((item, index) => ({
    ...item,
    publishedAt: index === 0 ? firstItemSubtitle : formatDate(item.publishedAt),
  }));

  return (
    <Section title={title} description={description}>
      <div className={styles.row}>
        {itemsWithDate.map((article) => (
          <div key={`article__${article.slug}`} className={styles.column}>
            <Article
              title={article.title}
              publishedAt={article.publishedAt}
              photo={article.imageUrl}
              text={article.excerpt}
              buttonLabel={itemButtonLabel}
              link={`${MEDIUM_URL}/${article.slug}`}
            />
          </div>
        ))}
      </div>
    </Section>
  );
};

export default Articles;
