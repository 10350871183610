import React from 'react';
import classNames from 'classnames';
import { YOUTUBE_VIDEO_ALLOW_OPTIONS } from '~/constants';

import styles from './Video.module.scss';

import { VideoDTO } from '../../../types';
import Button from '~/components/Button';

type Props = {
  video: VideoDTO;
  isThumbnail?: boolean;
};

const Video: React.FC<Props> = ({
  video: { title, titleSecondLine, type, url, embedUrl, length, description },
  isThumbnail,
}) => {
  const classes = classNames(styles.article, {
    [styles.thumbnail]: isThumbnail,
  });

  return (
    <article className={classes}>
      <div className={styles.videoContent}>
        <div className={styles.videoWrapper}>
          <iframe
            title={title}
            className={styles.video}
            src={embedUrl}
            frameBorder='0'
            allow={YOUTUBE_VIDEO_ALLOW_OPTIONS.join(',')}
            allowFullScreen
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.caption}>
          {type}
          <span className={styles.length}>{length}</span>
        </div>
        <h3 className={styles.title}>
          <Button
            as={{
              tag: 'a',
              href: url,
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
            className={styles.url}
            isText
          >
            {title}
            {titleSecondLine && (
              <>
                <br />
                {titleSecondLine}
              </>
            )}
          </Button>
        </h3>
        {description && <p className={styles.description}>{description}</p>}
      </div>
    </article>
  );
};

export default Video;
