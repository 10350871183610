import React from 'react';
import Section from '~/pages/resources/components/Section';

import Video from './Video';
import { VideosSectionDTO } from '../../types';

import styles from './Videos.module.scss';

type Props = {
  data: VideosSectionDTO;
  innerRef: React.RefObject<HTMLDivElement>;
};

const Videos: React.FC<Props> = ({
  data: { title, mainVideo, videos, description },
  innerRef,
}) => {
  const renderVideos = () =>
    videos.map((video, index) => (
      <Video key={index} video={video} isThumbnail />
    ));

  return (
    <>
      <Section innerRef={innerRef}>
        <Video video={mainVideo} />
      </Section>
      <Section title={title} description={description}>
        <div className={styles.list}>{renderVideos()}</div>
      </Section>
    </>
  );
};

export default Videos;
