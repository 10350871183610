import React from 'react';

import Section from '../Section';

import { DesignDTO, DesignSectionDTO } from '../../types';
import styles from './Design.module.scss';
import Button from '~/components/Button';

type Props = {
  data: DesignSectionDTO;
};

const Design: React.FC<Props> = ({ data: { title, description, items } }) => {
  const renderItems = (
    { image: { src, alt }, title, url }: DesignDTO,
    index: number,
  ) => (
    <article className={styles.column} key={index}>
      <Button
        as={{
          tag: 'a',
          href: url,
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
        className={styles.link}
        isText
      >
        <img
          className={styles.figure}
          src={src}
          alt={alt}
          role='presentation'
        />
        <h5 className={styles.title}>{title}</h5>
      </Button>
    </article>
  );

  return (
    <Section title={title} description={description} lastSection>
      <div className={styles.row}>{items.map(renderItems)}</div>
    </Section>
  );
};

export default Design;
