import { OpenSourceDTO } from '~/pages/resources/types';

import metadata from './metadata';
import entrySection from './entrySection';
import articlesSection from './articlesSection';
import openSourceSection from './openSourceSection';
import videosSection from './videosSection';
import designSection from './designSection';

const resources: OpenSourceDTO = {
  metadata,
  entrySection,
  articlesSection,
  openSourceSection,
  videosSection,
  designSection,
};

export default resources;
