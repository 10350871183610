import { VideosSectionDTO } from '~/pages/resources/types';

const videosSection: VideosSectionDTO = {
  title: 'Videos',
  description:
    'Get to know our team better, see how SwingDev works, learn something new! Subscribe to our <a href="https://www.youtube.com/channel/UCE_-lgACoTF8Mwb74r8DxaQ?view_as=subscriber" target="_blank" rel="noopener noreferrer">YouTube channel</a> to stay up to date.',
  mainVideo: {
    title: 'SwingTalk Podcast:',
    titleSecondLine: 'A Decade in Programming',
    length: '35 min',
    type: 'VIDEO PODCAST',
    url: 'https://www.youtube.com/watch?v=oIydVtNPgyE',
    embedUrl: 'https://www.youtube.com/embed/oIydVtNPgyE',
    description:
      'How is making web applications different today from 10 years ago? Is it now easier to start learning how to code than it was a decade ago? What has changed for worse, and what for the better? Watch our discussion to find out!',
  },
  videos: [
    {
      title: 'How to Find a Good Remote Development Team',
      length: '40 min',
      type: 'WEBINAR',
      url: 'https://www.youtube.com/watch?v=nCyQBJy2iOg',
      embedUrl: 'https://www.youtube.com/embed/nCyQBJy2iOg',
    },
    {
      title: 'Embracing DevOps Without Creating DevOps Silos',
      length: '31 min',
      type: 'WEBINAR',
      url: 'https://www.youtube.com/watch?v=38RTJeMlJgw',
      embedUrl: 'https://www.youtube.com/embed/38RTJeMlJgw',
    },
    {
      title: 'Why You Should Create Apps for Foldable Phones',
      length: '2 min',
      type: 'SHOWREEL',
      url: 'https://www.youtube.com/watch?v=lvbWpZevT6M',
      embedUrl: 'https://www.youtube.com/embed/lvbWpZevT6M',
    },
  ],
};

export default videosSection;
