import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import Slide from './Slide';

import { BrowserService } from '~/services/Browser';
import { Breakpoint } from '~/constants';
import Section from '~/pages/resources/components/Section';
import Carousel, { CarouselSettings } from '~/components/Carousel';

import { OpenSourceSectionDTO, OpenSourceProjectDTO } from '../../types';
import styles from './OpenSource.module.scss';

const carouselSettings: CarouselSettings = {
  dots: true,
  draggable: true,
  infinite: true,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: styles.dots,
};

type Props = {
  data: OpenSourceSectionDTO;
};

const shouldShowSlider = () => {
  return BrowserService.getWindowSize() < Breakpoint.LARGE;
};

const renderSlide = (project: OpenSourceProjectDTO, index: number) => (
  <Slide
    key={index}
    title={project.title}
    description={project.description}
    link={project.url}
    iconUrl={project.icon.src}
    linkLabel={project.urlLabel}
  />
);

const renderCarousel = (items: OpenSourceProjectDTO[]) => (
  <Carousel {...carouselSettings}>{items.map(renderSlide)}</Carousel>
);

const renderItems = (items: OpenSourceProjectDTO[]) => (
  <>{items.map(renderSlide)}</>
);

const OpenSource: React.FC<Props> = ({
  data: { title, items, description },
}) => {
  const [showMobileSlider, setShowMobileSlider] = useState(shouldShowSlider());

  const debouncedHandleResize = debounce(() => {
    setShowMobileSlider(shouldShowSlider());
  }, 100);

  useEffect(() => {
    BrowserService.resize.addResizeListener(debouncedHandleResize);

    return () =>
      BrowserService.resize.removeResizeListener(debouncedHandleResize);
  }, [debouncedHandleResize]);

  return (
    <Section title={title} description={description}>
      <div className={styles.row}>
        {showMobileSlider ? renderCarousel(items) : renderItems(items)}
      </div>
    </Section>
  );
};

export default OpenSource;
